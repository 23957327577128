// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {

  closePaypal() {
    document.getElementById("paypal-button-container").innerHTML = ""
  }

  afterRender() {
    // const sleep = ms => new Promise(r => setTimeout(r, ms));
    // sleep(2000);
    // document.querySelector('.paypal-button.paypal-button-number-0').setAttribute('onclick', "return gtag_report_conversion('http://example.com/your-link')")
    // console.log(document.querySelector('.paypal-button.paypal-button-number-0'))
    document.querySelectorAll('[allowpaymentrequest]')[0].setAttribute('onclick', "return gtag_report_conversion('https://www.googletagmanager.com/gtag/js?id=AW-10849801031')")

  }

  setPaypal() {
    if (typeof paypal === undefined) {
      return;
    }

    paypal.Buttons({
      style: {
        layout: 'vertical',
        shape:  'rect',
        label:  'paypal'
      },


      funding: {
        disallowed: [paypal.FUNDING.PAYLATER],
      },

      createOrder: function (data, actions) {
        const donation_amount = + document.getElementById('donation_amount').value;
        const value_tip = +document.getElementById('value_tip').value;
        const total = donation_amount + value_tip;
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: total < 5 ? 5 : total
            }
          }]
        });
      },

      onApprove:  function(data, actions) {
        return actions.order.capture()
          .then(function (details) {
            return fetch('/paypal-transaction-complete', {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                orderID: data.orderID,
                email: details.payer.email_address,
                donor_email: document.getElementById('donor_email').value === '' ? details.payer.email_address : document.getElementById('donor_email').value,
                fullname: details.payer.name.given_name + " " + details.payer.name.surname,
                donor_name: document.getElementById('donor_fullname').value === '' ? details.payer.name.given_name + " " + details.payer.name.surname : document.getElementById('donor_fullname').value,
                tip_amount: Number(document.getElementById('value_tip').value),
                campaign_id: document.getElementById('campaign_id').value,
                anonymous: document.getElementById('donor_as_anonymous').value
              })
            })
            .then(response => response.json())
            .then(data => {
              window.location.href = data["redirect_url"];
            })
            .catch((error) => {
              console.error('Error:', error);
            });
          });
      }
    }).render('#paypal-button-container');
    this.afterRender()
  }

}
