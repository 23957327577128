// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "ghs",
    "amount",
    "tip",
    "donation",
    "ctip",
    "customInput",
    "paystackBtn",
    "paystackApi",
    "total"
  ];

  connect() {
    this.updateValue();
    this.convert();
  }

  total() {
    let donationAmount = this.amountTarget.value;
    if (donationAmount === "") {
      donationAmount = 0;
    }
    const total = parseFloat(this.tipTarget.value) + parseFloat(donationAmount);
    return total.toFixed(2);
  }

  updateValue() {
    this.amountTarget.value = parseFloat(this.amountTarget.value).toFixed(2);
    this.customInputTarget.value = parseFloat(
      this.customInputTarget.value
    ).toFixed(2);
  }

  display(ghs) {
    // console.log(this.paypalPayTarget.innerText);
    let total = 0;
    let converted = 0;
    if (this.total() > 0) {
      total = `$${this.total()}`;
      converted = ` / GHS ${ghs}`;
    }
    // this.paypalPayTarget.innerText = `Pay ${total} with PayPal / Credit Card`;
    // this.paypalPayTarget.innerHTML = `<span class="amount-to-pay">${total}</span>PayPal`;
    // this.giftTarget.innerHTML = `<span class="amount-to-pay">${total}</span>Gift card`;
    // this.mobileTarget.innerHTML = `<span class="amount-to-pay">${total}${converted}</span>Pay for Ghanaians`;
    const ghanaPay = document.getElementById('mobileMoney').style;
    // this.donationTarget.innerText = `$${this.amountTarget.value || 0}`;
    // this.ctipTarget.innerText = this.tipTarget.value;
    // this.totalTarget.innerText = ghanaPay.display == 'block' ?`${total}${converted}` : `${total}`;
    this.paystackBtnTarget.innerHTML = `Donate <span class="amount-to-pay">${total}</span>`;
    this.paystackApiTarget.value = ghs;
  }

  convert() {
    const total = this.total();
    const rate = parseFloat(this.ghsTarget.getAttribute("data-rate"));
    const ghs = (total * rate).toFixed(2);
    this.ghsTarget.innerText = ghs;
    this.display(ghs);
  }
}