// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus";
import regeneratorRuntime from "regenerator-runtime";


export default class extends Controller {
  initialize() {
      (async function () {
        const payments = Square.payments(
        'sandbox-sq0idb-Cx67N3TfR04YRuc4ZesLdQ',
        'L2RHX03AWGNJV'
      );

      const cardOptions = {
        style: {
          input: {
            backgroundColor: 'White'
          },
        }
      };

      console.log(payments, cardOptions);
    })();
  }
} 