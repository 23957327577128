import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['home', 'paymentDetails', 'socialMedial']
  
  initialize() {
    this.btns = document.querySelectorAll('.details-charity-det > .admin-min-nav button')
  }

  connect() {
    if(this.homeTarget) {
      this.homeTarget.style.display = 'block';
      this.paymentDetailsTarget.style.display = 'none';
      this.socialMedialTarget.style.display = 'none'
    }
  }

  handleSections(event) {
    switch(event.target.dataset.role) {
      case 'paymentDetails':
        this.activeLink(event.target, this.btns);
        this.homeTarget.style.display = 'none';
        this.paymentDetailsTarget.style.display = 'block';
        this.socialMedialTarget.style.display = 'none'
      break;

      case 'socialMedial':
        this.activeLink(event.target, this.btns);
        this.homeTarget.style.display = 'none';
        this.paymentDetailsTarget.style.display = 'none';
        this.socialMedialTarget.style.display = 'block'
      break;

      default:
        this.activeLink(event.target, this.btns);
        this.homeTarget.style.display = 'block';
        this.paymentDetailsTarget.style.display = 'none';
        this.socialMedialTarget.style.display = 'none'
    }
  }

  activeLink(element, btns) {
    btns.forEach((btn) => {
      btn.classList.remove('active')
    });
    element.classList.add('active');
  }

  copyElement(event) {
    const parentDiv = event.target.parentNode;
    const input = parentDiv.querySelector('input');
    navigator.clipboard.writeText(input.value);
    event.target.innerHTML = '<i class="fa fa-check" aria-hidden="true"></i>';
    event.target.setAttribute('title', 'Copied');
    setTimeout(() => {
      event.target.innerHTML = 'Copy';
      event.target.setAttribute('title', '');
    }, 3000);
  }

  changeColor(e) {
    const color = e.target.value;
    const charitySlug = e.target.dataset.slug;
    
    fetch(`/charities/change-color/${charitySlug}`, {
      method: 'put',
      headers: {
        'content-type': 'application/json'
      },

      body: JSON.stringify({
        color
      })
    })
  }
}