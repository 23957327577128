// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["searchInput", "charitiesContainer", "suggestionContainer", "accurateContainer"]

  connect() {
    this.charitiesContainerTarget.style.display = 'none';
  }

  getSearchCharities(search) {
    const config = {
      method: 'POST',
      headers: {
        'Content-type': "application/json"
      },
      body: JSON.stringify({search_charity: search})
    };

    try {
      const resp = fetch('/charities/search_charity', config);
      resp.then(resp => resp.json()).
      then(data => {
        let charities = this.displayCharity(data.accurate, search);
        let suggestion = this.displayCharity(data.suggestion, search);

        this.accurateContainerTarget.innerHTML = charities;
        this.suggestionContainerTarget.innerHTML = suggestion;
      });
    }
    catch(error) {
      console.error(error)
    }
  }

  displayCharity(charities, str) {
    let charitiesHtml = '';
    charities.forEach((charity) => {
      charitiesHtml += `
      <div class=" d-flex justify-content-between p-2 border-bottom">
        <div class="search-result">
          <h6 class="m-0">${charity.charity_name.replace(new RegExp(str, 'i'), `<strong>${str}</strong>`)}</h6>
          <span>${charity.reg_no ? charity.reg_no.replace(new RegExp(str, 'i'), `<strong>${str.toUpperCase()}</strong>`): ''}</span>
        </div>
        ${
          charity.custom_page ? `<a class="btn border-success text-black result-btn" href="/custom_pages/${charity.custom_page.slug}">View</a>`
                            : `<a class="btn bg-success text-white result-btn" href="/charities/${charity.slug}/claim_requests/new">Claim</a>`
        }
      </div>
      `
    });

    return charitiesHtml;
  }

  typingSearch() {
    this.charitiesContainerTarget.style.display = 'block';
    const inputValue = this.searchInputTarget.value;
    
    if(inputValue.length > 2) this.getSearchCharities(inputValue);

    if(inputValue.length === 0) {
      this.charitiesContainerTarget.style.display = 'none';
      this.accurateContainerTarget.innerHTML = '';
      this.suggestionContainerTarget.innerHTML = '';
    }
  }
}