// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['bar', 'info']
    connect() {
        this.updateProgress();
    }

    updateProgress() {
        const input = document.getElementById('donation_amount');
        const totalAmount = +this.infoTarget.dataset.total;
        const amountDonated = +this.infoTarget.dataset.valuenow;
        const amountP = ((amountDonated + (+input.value)) / totalAmount) * 100;
        this.barTarget.style.width = `${amountP > 100 ? 100 : amountP }%`;
        if(amountP >= 100) {
            this.barTarget.classList.remove('bg-warning');
            this.barTarget.classList.add('bg-success');
        } else {
            this.barTarget.classList.remove('bg-success');
            this.barTarget.classList.add('bg-warning');
        }
        this.infoTarget.innerHTML= `${ amountP > 100 ? 100 : amountP.toFixed(2) }%`;
    }
}