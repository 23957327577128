import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ 'box' ]

  connect() {
    this.boxTarget.style.display = 'none'
  }

  showDetailsBox () {
    this.boxTarget.style.display = 'flex'
  }

  closeDetailsBox () {
    this.boxTarget.style.display = 'none'
  }
}