import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['container', 'campaignId', 'reference']

  connect() {
    const paystackForm = document.getElementById('paystackForm');

    paystackForm.addEventListener('submit', (e) => this.payWithPaystack(e), false);
  }

  payWithPaystack(e) {
    e.preventDefault();
    const anonymous = document.getElementById('donor_as_anonymous').value;
    const fullname = this.containerTarget.dataset.fullname || document.getElementById('donor_fullname').value;
 
    let handler = PaystackPop.setup({
      key: process.env.PAYSTACK_PUBLIC_KEY,
      email: this.containerTarget.dataset.email || document.getElementById('donor_email').value,
      amount: +(document.getElementById('paystack_amount').value * 100).toFixed(),
      currency: 'GHS',
      channels: ['mobile_money', 'card'],
      metadata: { campaign_code: this.campaignIdTarget.value, anonymous, fullname },
      ref: this.referenceTarget.value,

      
      onClose: function(){
        location.reload();
      },


      callback: (response) => {
        const data = {
          reference: response.reference,
          campaign_id: this.campaignIdTarget.value
        };

        fetch('/donation/paystack', {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((data) => {
          window.location.href = data["redirect_path"];
        })
        .catch((error) => {
          console.error(error);
        })
      }
    });
    handler.openIframe();
   }
}