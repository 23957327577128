// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "valueInput" ]

  setValue(e) {
    this.valueInputTarget.value = e.target.dataset.amount;
  }

  gotTogivingPage(e) {
    e.preventDefault();
    let amount = this.valueInputTarget.value;
    let url = `/custom_pages/new?page=individual&goal=${amount}`;
    window.location.href = url;
  }
}
