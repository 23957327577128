// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['bgindex'];
    
    initialize() {
        this.index = 0;
        this.showSlides();
    };

    connect() {
        let imagesButtons = document.querySelectorAll('.optional-images > .image-box');
        for(let i = 0; i < imagesButtons.length - 1; i++) {
            imagesButtons[i].addEventListener('click', () => {
                this.index = i;
                this.showSlides();
            });
        };
    };

    next() {
        this.index++;
        this.showSlides();
    };

    previous() {
        this.index--;
        this.showSlides();
    }

    showSlides() {
        let imagesButtons = document.querySelectorAll('.optional-images > .image-box');
        const imagesBox = document.querySelectorAll('.background-image-info > .image-box');
        const bgImage = this.bgindexTarget;
        if(this.index >= imagesBox.length  || this.index < 0) this.index = 0;

        imagesButtons.forEach((button) => {
            button.classList.remove('active')
        });

        imagesBox.forEach((image) => {
            image.classList.remove('active');
        });
 
        imagesBox[this.index].classList.add('active');
        imagesButtons[this.index].classList.add('active');
        bgImage.value = this.index;
    }

    loadFile(event) {
        let imagesButtons = document.querySelectorAll('.optional-images > .image-box');
        const container = document.querySelector('.background-image-card');
        if(event.target.files) {
          container.innerHTML = `<div><img src="${URL.createObjectURL(event.target.files[0])}"
                                    alt="Selected image"
                                    style="width: 494px;
                                    height: 322px;">
                                </div>`;
        
          imagesButtons.forEach((button) => {
            button.classList.remove('active')
          });
          this.index = imagesButtons.length -1;
          imagesButtons[this.index].classList.add('active');
        }
    };
}