// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["business", "community", "searchCharityInput"];

  connect() {
    this.change();
    const radios = document.forms[0].elements["custom_page[select_campaigns]"];
    const articles = document.querySelectorAll("article");
    this.handleArticle(articles, "");
    radios.forEach((radio) => {
      if (radio.checked) {
        const article = Array.from(articles).find(
          (article) => radio.value.replace(" ", "_") === article.id
        );
        this.handleArticle(articles, article);
      }
      radio.onclick = () => {
        const article = Array.from(articles).find(
          (article) => radio.value.replace(" ", "_") === article.id
        );
        this.handleArticle(articles, article);
      };
    });
  }

  handleArticle(articles, element) {
    articles.forEach((article) => {
      if (element === article) article.style.display = "block";
      else article.style.display = "none";
    });
  }

  change() {
    const pageType = document.getElementById("pageType");

    if (pageType) {
      switch (pageType.value) {
        case "business":
          this.businessTarget.style.display = "none";
          this.communityTarget.style.display = "block";
          break;
        case "community":
          this.communityTarget.style.display = "none";
          this.businessTarget.style.display = "block";
          break;
        default:
          this.communityTarget.style.display = "block";
          this.businessTarget.style.display = "block";
      }
    }
  }

  searchCharity() {
    const charityContainer = document.getElementById("charityContainer");
    const options = {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ search: this.searchCharityInputTarget.value }),
    };

    fetch("/search_charity", options)
      .then((response) => response.json())
      .then((data) => {
        charityContainer.innerHTML = (() => {
          console.log(data);
          if (data) {
            return `
              <label class="d-flex align-items-start gap-2">
                <input type="checkbox" name="custom_page[charity_chosen]" value="${data.id}" checked>
                <div class="">
                  <img src="${data.avatar_url}" alt="${data.charity_name}" class="selected-campaign-img" />
                  <p class="m-0 form-text">${data.charity_name}</p>
                  <p class="m-0 form-text">Registration number: ${data.reg_no}</p>
                </div>
              </label>`;
          } else {
            return `<p style="color: red">Charity not found</p>`;
          }
        })();
        const charityBox = charityContainer.querySelector("label > input");
        charityBox.onclick = () => {
          charityBox.parentNode.remove();
        };
      });
  }
}
