import {Controller} from "stimulus"

export default class extends Controller {

  openSocialMediaBox(e) {
    const url = e.target.dataset.url || e.target.parentNode.dataset.url;
    window.open(
      url,
      url.includes('facebook') ? 'facebook_share' : 'twitter_share',
      'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no'
    );
  }

}
