import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'btn', 'input', 'imageBox']

  connect() {
    console.log(this.imageBoxTarget.getAttribute('data-image'))
    if(this.imageBoxTarget.getAttribute('data-image') === 'true') {
      this.inputTarget.style.display = 'none';
      this.imageBoxTarget.style.display = 'flex';
    } else {
      this.inputTarget.style.display = 'block';
      this.imageBoxTarget.style.display = 'none';
    }
  }

  handleImage(e) {
    console.log(typeof(e.target.files))
    if (e.target.files) {
      let spans = '';
      Object.values(e.target.files).forEach((file) => {
        spans += `<span class="form-text me-1">
        ${file.name}
      </span>`;
      });
      this.imageBoxTarget.querySelector('span').innerHTML = spans;
      this.inputTarget.style.display = 'none';
      this.imageBoxTarget.style.display = 'flex';
    }
  }

  closeImage() {
    this.inputTarget.style.display = 'block';
    this.imageBoxTarget.style.display = 'none';
    console.log(this.imageBoxTarget.getAttribute('data-image'))
  }
}