import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['formB', 'docInput', 'fileName']

  uploadFormB(e) {
    if(e.target.files) {
      this.fileNameTarget.textContent = e.target.files[0].name
    }
  }
}