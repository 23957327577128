import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "searchCampaignInput",
    "chosenCampaigns",
    "campaignsContainer",
    "addButton",
  ];

  initialize() {
    this.campaigns = [];
    this.searchResult = [];
  }

  waschose(campaign) {
    return this.campaigns.some((e) => e.id === campaign.id);
  }

  disabledInputs() {
    if (this.campaigns.length >= 3) {
      this.searchCampaignInputTarget.setAttribute("disabled", "");
      this.addButtonTarget.setAttribute("disabled", "");
      campaignsContainer.innerHTML = "";
    } else {
      this.searchCampaignInputTarget.removeAttribute("disabled");
      this.addButtonTarget.removeAttribute("disabled");
    }
  }

  searchCampaigns(e) {
    const campaignsContainer = document.getElementById("campaignsContainer");
    const options = {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ search: this.searchCampaignInputTarget.value }),
    };

    if (
      !/^[\d,\s]+$/.test(this.searchCampaignInputTarget.value) ||
      e.target.dataset.button == "yes"
    ) {
      fetch("/search_campaigns", options)
        .then((response) => response.json())
        .then((data) => {
          this.searchResult = data;
          if (e.target.dataset.button === "yes") {
            if (data.length > 0) campaignsContainer.innerHTML = "";
            else if (this.campaigns.length === 3)
              return (campaignsContainer.innerHTML = `<p style="color: red">Campaigns number exceed 3</p>`);
            else
              return (campaignsContainer.innerHTML = `<p style="color: red">No result found</p>`);

            this.searchResult.forEach((result) => {
              if (!this.waschose(result) && this.campaigns.length < 3)
                this.campaigns.push(result);
            });
            this.chosenCampaignsTarget.innerHTML = this.displayChosen();
            this.removeChosenCampaign();
            this.disabledInputs();
          } else {
            campaignsContainer.innerHTML = (() => {
              if (data.length > 0) {
                return this.displayCampaigns(data);
              } else {
                return `<p style="color: red">No result found</p>`;
              }
            })();
          }
        });
    }
  }

  removeChosenCampaign() {
    const checkboxes =
      this.chosenCampaignsTarget.querySelectorAll("label > input");
    checkboxes.forEach((checkbox) => {
      checkbox.onchange = () => {
        if (!checkbox.checked) {
          this.campaigns = this.campaigns.filter(
            (campaign) => campaign.id != checkbox.value
          );
          checkbox.parentNode.remove();
          this.disabledInputs();
        }
      };
    });
  }

  displayCampaigns(campaigns) {
    let campaignsHtml = "";
    campaigns.forEach((campaign) => {
      campaignsHtml += `<div class="d-flex flex-wrap w-100 gap-3 px-3 py-2 ${
        this.waschose(campaign) ? "chose" : ""
      }">
      <img src="${campaign.campaign_image_url}" alt="${
        campaign.campaign_headline
      }" class="add-campaign-manually-img" />
      <div class="flex-fill">
        <p class="mb-1">${campaign.campaign_name}</p>
        <p class="m-0">Campaign ID: ${campaign.id}</p>
      </div>
      ${
        !this.waschose(campaign)
          ? `<button type="button" class="add-campaign-btn-add btn btn-success" data-action="add-campaigns-manually#addCampaign" data-campaignid="${campaign.id}">Add</button>`
          : ""
      }
      </div>`;
    });

    return campaignsHtml;
  }

  addCampaign(e) {
    const campaign = this.searchResult.find(
      (element) => element.id == e.target.dataset.campaignid
    );

    if (!this.waschose(campaign)) {
      this.campaigns.push(campaign);
      e.target.style.display = "none";
      e.target.parentNode.classList.add("chose");
    }

    this.chosenCampaignsTarget.innerHTML = this.displayChosen();
    this.removeChosenCampaign();
    this.disabledInputs();
  }

  displayChosen() {
    let chosen = "";
    this.campaigns.forEach((campaign) => {
      chosen += `
          <label class="d-flex align-items-start gap-2">
          <input type="checkbox" name="custom_page[manually_chosen][]" value="${campaign.id}" checked>
            <div class="row">
            <img src="${campaign.campaign_image_url}" alt="${campaign.campaign_headline}"  class="selected-campaign-img" />
            <p class='m-0 form-text'>${campaign.campaign_name}</p>
            <p class='m-0 form-text'>Campaign ID: ${campaign.id}</p>
            </div>
          </label>`;
    });

    return chosen;
  }
}
