// import { Controller } from "stimulus";

// export default class extends Controller {
//   static targets = ["outputFinal"];

//   connect() {
//     this.scrollImageAction();
//     // console.log("scrollImageAction called", this.inputTarget);
//   }

//   scrollImageAction() {
//     const containers = this.containerTargets;

//     const scrollSpeed = 0.4;
//     let scrollAccumulator = 0;

//     // const scrollImages = () => {
//     //   if (containers.every((container) => container)) {
//     //     scrollAccumulator += scrollSpeed;
//     //     if (scrollAccumulator >= 1) {
//     //       const scrollAmount = Math.floor(scrollAccumulator);
//     //       containers.forEach((container) => {
//     //         container.scrollLeft -= scrollAmount;
//     //         if (container.scrollLeft <= 0) {
//     //           container.scrollLeft = container.scrollWidth / 2;
//     //         }
//     //       });
//     //       scrollAccumulator -= scrollAmount;
//     //     }
//     //     requestAnimationFrame(scrollImages);
//     //   }
//     //   else{
//     //     console.log("containers not found");
//     //   }
//     // };

//     // containers.forEach((container) => {
//     //   if (container) {
//     //     const images = container.innerHTML;
//     //     container.innerHTML = images + images;
//     //     container.scrollLeft = container.scrollWidth / 2;
//     //   }
//     // });

//     // scrollImages();

//         const rightContainer = containers
//           ? document.getElementById(containers)
//           : null;

//         function scrollImages(container, direction) {
//           if (container) {
//             if (direction === "right") {
//               scrollAccumulator += scrollSpeed;
//               if (scrollAccumulator >= 1) {
//                 container.scrollLeft += Math.floor(scrollAccumulator);
//                 scrollAccumulator -= Math.floor(scrollAccumulator);
//               }
//               if (container.scrollLeft >= container.scrollWidth / 2) {
//                 container.scrollLeft -= container.scrollWidth / 2;
//               }
//             } else if (direction === "left") {
//               scrollAccumulator += scrollSpeed;
//               if (scrollAccumulator >= 1) {
//                 container.scrollLeft -= Math.floor(scrollAccumulator);
//                 scrollAccumulator -= Math.floor(scrollAccumulator);
//               }
//               if (container.scrollLeft <= 0) {
//                 container.scrollLeft += container.scrollWidth / 2;
//               }
//             }
//             requestAnimationFrame(() => scrollImages(container, direction));
//           }
//         }

//         if (rightContainer) {
//           // Clone the images to create a seamless loop
//           const rightImages = rightContainer.innerHTML;
//           rightContainer.innerHTML = rightImages + rightImages;

//           scrollImages(rightContainer, 'left');
//         }
//         else{
//           console.log("containers not found");
//           console.log("containers not found", this.targets);
//         }
//   }
// }

document.addEventListener("turbolinks:load", () => {

  scrollImageAction({
    containerElements: [
      "leftNewSmallScrollingContainer",
      "leftNewBigScrollingContainer",
      "leftNewFinalSmallScrollingContainer",
    ],
    scrollDirection: "left",
    scrollSpeedNumber: 0.4,
  });

  function scrollImageAction({
    containerElements,
    scrollDirection,
    scrollSpeedNumber,
  }) {
    const containers = containerElements.map((id) =>
      document.getElementById(id)
    );
    const scrollSpeed = scrollSpeedNumber;
    let scrollAccumulator = 0;

    function scrollImages() {
      if (containers.every((container) => container)) {
        scrollAccumulator += scrollSpeed;
        if (scrollAccumulator >= 1) {
          const scrollAmount = Math.floor(scrollAccumulator);
          containers.forEach((container) => {
            if (scrollDirection === "left") {
              container.scrollLeft -= scrollAmount;
              if (container.scrollLeft <= 0) {
                container.scrollLeft = container.scrollWidth / 2;
              }
            } else if (scrollDirection === "right") {
              container.scrollLeft += scrollAmount;
              if (container.scrollLeft >= container.scrollWidth / 2) {
                container.scrollLeft = 0;
              }
            }
          });
          scrollAccumulator -= scrollAmount;
        }
        requestAnimationFrame(scrollImages);
      }
    }

    containers.forEach((container) => {
      if (container) {
        const images = container.innerHTML;
        container.innerHTML = images + images;
        if (scrollDirection === "left") {
          container.scrollLeft = container.scrollWidth / 2;
        }
      }
    });

    scrollImages();
  }

});
