// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["radioButton", "form", "amountInput"]

  connect() {
    this.radioButtonTargets.forEach(radioButton => {
      if (radioButton.checked) {
        const form = this.formTargets.find(form => form.id === radioButton.dataset.formId);
        form.style.display = 'block';
      }
    });
  }

  select(e) {
    this.formTargets.forEach(form => {
      if (form.id === e.target.dataset.formId) {
        form.style.display = 'block';
        (e.target.dataset.formId === "paypal_skd") ? this.addMinMessage(e.target) : this.removeMinMessage();
      } else {
        form.style.display = 'none';
      }
    });
  }

  addMinMessage(paypalRadio) {
    if(paypalRadio.checked && +this.amountInputTarget.value < 5) {
      const message = '<em id="min-message" style="color: red">Please the Minimum amount for paypal payment is $5 per donation</em>';
      this.amountInputTarget.insertAdjacentHTML(
        'afterend',
        message
      );
    }
  }

  removeMinMessage() {
    const minMessage = document.getElementById('min-message');
    if(minMessage) {
      minMessage.remove()
    }
  }

  removeMinmessage() {
    const minMessage = document.getElementById('min-message');
    const paypalRadio = this.radioButtonTargets.find(radio => radio.dataset.formId === "paypal_skd");
    if(minMessage && (!paypalRadio.checked || +this.amountInputTarget.value >= 5)) {
      minMessage.remove();
    } else if(!minMessage) {
      this.addMinMessage(paypalRadio)
    }
  }
}
