import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hamburgerMenu', 'navigationBar'];

  connect() {
    // console.log(this.navigationBarTarget.style)
    // this.navigationBarTarget.style.transform = 'translateX(-100%)';
    // this.navigationBarTarget.style.width = '0px';
    // if (window.screen.width < 700) this.navigationBarTarget.classList.add('active')
  }

  toggle() {
    this.navigationBarTarget.classList.toggle('active');
    this.hamburgerMenuTarget.classList.toggle('nav-open');
  }
}