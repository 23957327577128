import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["projects", 'campaigns', 'members'];

    connect() {
        const btnProjects = document.getElementById('projects');
        const btnCampaigns = document.getElementById('campaigns');
        const btnMembers = document.getElementById('members');

        const ftnDisplay = (ItemBtn, ItemBox) => {
            const btns = document.querySelectorAll('.content-head-item');
            const boxes = document.querySelectorAll('.content-body');

            btns.forEach((btn) => {
                btn.classList.remove('active-head')
            });

            boxes.forEach((box) => {
                box.classList.remove('active-body')
            });

            ItemBtn.classList.add('active-head')
            ItemBox.classList.add('active-body')
        }

        btnProjects.addEventListener('click', () => {
            const box = document.querySelector('.box-projects')
            ftnDisplay(btnProjects, box);
        });

        btnCampaigns.addEventListener('click', () => {
            const box = document.querySelector('.box-campaigns');
            ftnDisplay(btnCampaigns, box);
        });

        btnMembers.addEventListener('click', () => {
            const box = document.querySelector('.box-members');
            ftnDisplay(btnMembers, box);
        });
    }
}