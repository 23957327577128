import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['charityDetails', 'personalDetails']

  connect() {
    this.personalDetailsTarget.style.display = 'none';
  }

  showPersonalDetails() {
    this.charityDetailsTarget.style.display = 'none';
    this.personalDetailsTarget.style.display = 'block';
  }

  showCharityDetails() {
    this.personalDetailsTarget.style.display = 'none';
    this.charityDetailsTarget.style.display = 'block';
  }
}