// https://github.com/stimulusjs/stimulus#-stimulus
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'hiddenInput' ]

  connect() {

  }

  toggleCustomInput(e) {
    if (e.target === this.customRadioTarget) {
      this.customInputTarget.style.display = 'block';
      this.hiddenInputTarget.value = 0;
    } else {
      this.customInputTarget.style.display = 'none';
      this.customInputTarget.value = '';
    }
  }

  updateHiddenInput(e) {
    this.hiddenInputTarget.value = e.target.value;
  }
}
